<template>
  <div class="eui-index-bgw eui-footer">
      <div class="eui-index-contact">
    <div class="pub-con">
      <div
        class="title"
        data-aos="fade-left"
        data-aos-duration="600"
        data-aos-delay="200"
      >
        联系我们<span>CONTACT US</span>
      </div>
      <div class="clearfix">
        <div
          class="lfloat location"
          data-aos="fade-left"
          data-aos-duration="600"
          data-aos-delay="500"
        >
          <div class="slideTxtBox">
            <!-- <div class="hd">
              <ul class="clearfix">
                <li>福州总部</li>

                <li>上海分公司</li>

                <li>北京分公司</li>
              </ul>
            </div> -->
            <div class="bd">
              <div class="info">
                <p>
                  <span style="font-family: 微软雅黑">
                    地址：福建省福州市台江区茶亭街道广达路68号金源大广场<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;（金源中心）东区24层南侧 
                  </span>
                </p>
                <p>
                  <span style="font-family: 微软雅黑"
                    >邮编：350003　　　邮箱：cm@tcinfo.com</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="lfloat employ"
          data-aos="fade-left"
          data-aos-duration="600"
          data-aos-delay="700"
        >
          <strong>应聘投递与咨询</strong>
          <p>
            <span style="font-family: 微软雅黑"
              >邮箱：<a href="lqg@tcinfo.com">cm@tcinfo.com</a
              >　&nbsp; &nbsp; &nbsp;
              <!-- <a href="mailto:campus@evecom.net"
                >campus@evecom.net（校招）</a
              > -->
              </span
            >
            <span style="font-family: 微软雅黑"
              >电话：0591-83053932</span
            >
          </p>
          <!-- <p>
            <span style="font-family: 微软雅黑"
              >电话：0591-83053932</span
            >
          </p> -->
        </div>
        <div
          class="rfloat code"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="9000"
        >
          <img src="../images/index-contact-code.jpg" style="width: 120px;margin-bottom:4px"/>
          <span>企业微信公众号</span>
        </div>
      </div>
      <div class="copyright">
        版权所有 福建省泰昌信息技术有限公司
          <img src="../images/ba.jpg" style="width: 14px;" />
          <span
            style="
              line-height: 20px;
              margin: 0px 5px 0px 0;
            "
          >
           闽ICP备17033070号-1
          </span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "bottom",
  props: {
    msg: String,
  },
  mounted() {
    // AOS.init();
  },
};
</script>

<style scoped>
@media screen and (max-width: 750px){
  .eui-footer {
    font-size: 12px;
    text-align: left;
  }
  .eui-footer .pub-con {
    width: 100%
  }
  .eui-footer .eui-index-contact {
    padding: 10px;
  }
  .eui-index-contact .location {border: none;width: 100%}
  .eui-index-contact .employ {
    width: 100%;
    padding: 0;
  }
  .eui-footer .rfloat {display: none;}
}
</style>
