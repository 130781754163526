<template>
  <div class="lfloat sidenav" data-aos="fade-right"
          data-aos-duration="600"
          data-aos-delay="600">
    <dl>
      <dd v-for="(item, index) in navData" :key="index" @click="getNavIndex(index)">
        <h3 :class="active == index?'on':''">
          <a>{{item.resName}}</a>
        </h3>
      </dd>
    </dl>
  </div>
</template>


<script>
export default {
  name: "leftNav",
  data() {
    return {
      active: 0,
      navData: [],
      textInfo: ''
    };
  },
  // props: {
  //   navData: {
  //     type: Array,
  //     default: []
  //   },
  // },
  mounted() {
    AOS.init();
    let that = this
    that.$api.ajaxPost('vueOfficialWebsite/getResourceList').then(res => {
      if(res.success){
        for(var i = 0;i < res.data.length;i++) {
          if (that.$route.query.navId == res.data[i].id) {
            that.navData = res.data[i].sonList
            that.$emit('getTextInfo', res.data[i].sonList[0])
          //   console.log(that.navData)
          }
        }
        
      }
    });
  },
  methods: {
    getNavIndex(index) {
      let that = this
      that.active = index
      that.$emit('getTextInfo', that.navData[index])
    },
  },
};
</script>
