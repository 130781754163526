import Vue from "vue";
import VueRouter from "vue-router";



Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        // 网站首页
        path: "/",
        name: "intro",
        component: () =>
            import("../views/index/index.vue"),
    },
    {
        // 关于泰昌
        path: "/companyIntro",
        name: "companyIntro",
        component: () =>
            import("@/views/companyIntro/companyIntro.vue"),
    },
    {
        // 产品与服务
        path: "/productService",
        name: "productService",
        component: () =>
            import("../views/productService/productService.vue"),
    },
    {
        // 公司文化
        path: "/companyCulture",
        name: "companyCulture",
        component: () =>
            import("../views/companyCulture/companyCulture.vue"),
    },
    {
        // 新闻资讯
        path: "/newsInfo",
        name: "newsInfo",
        component: () =>
            import("../views/newsInfo/newsInfoContent.vue"),
    },
    {
        // 新闻资讯
        path: "/details",
        name: "details",
        component: () =>
            import("../views/newsInfo/details.vue"),
    },
    {
        // 人才招聘
        path: "/talentRecruit",
        name: "talentRecruit",
        component: () =>
            import("../views/talentRecruit/talentRecruit.vue"),
    },
    {
        // 商务合作
        path: "/businessCoop",
        name: "businessCoop",
        component: () =>
            import("../views/businessCoop/businessCoop.vue"),
    },
];

const router = new VueRouter({
    routes,
});

export default router;