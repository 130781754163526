import axios from 'axios';
import { Message } from 'element-ui';
axios.defaults.baseURL = baseURL;
// import qs from 'qs';


// 跨域相关
axios.defaults.withCredentials = true;
// 请求超时时间
axios.defaults.timeout = 0;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//http request 拦截器
axios.interceptors.request.use(
  config => {
    config.headers = {'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8'}
    config.data = typeof config.data === 'object' ? JSON.stringify(config.data) : config.data
    return config;
  },
  error => {
    return Promise.reject(err);
  }
);

export default {
  /**
   * post 请求
   *
   * @param url 接口路由
   * @param data 接口参数
   * @param auth 是否需要带登录信息
   * @returns {AxiosPromise<any>}
   */
  ajaxPost(url, data, auth = false) {
    if (auth) {
      // return axios.post(url, data, { headers: { Authorization: '' } });
    } else {
      return new Promise((resolve,reject) => {
          axios.post(url,data,{withCredentials:true,arrayFormat: 'indices', allowDots: true})
              .then(response => {
                  if(!response.data.success){
                      if(response.hasOwnProperty("errorCode")){ //拦截拦截错误信息
                          errorHandle(response.data);
                          return;
                      }
                  }
                  resolve(response.data);
              },err => {
                  reject(err)
              })
      })
    }
  },
}

/**
 * 报错统一处理
 * @param data
 */
const errorHandle = (data) => {
    if(data.errorCode){
        //错误码存在
        switch (data.errorCode) {
            // 401: 未登录状态，跳转登录页
            case '7001':
                tip("会话失效！");
                break;
            case '7002':
                //alert('70002')
                //toLogin();
                break;
            case '7003':
                break;
            case '7005':
                break;
            default:
                console.log('http.js-other');
        }
    }else{
        if(!data.success){
            //console.log('http.js----'+data.msg);
        }
    }
};

/**
 * 提示框
 * @param msg
 */
const tip = msg => {
    Message({
        showClose: true,
        message: msg,
        type: 'error',
    });
};