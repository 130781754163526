import Vue from 'vue'
import ElementUI from 'element-ui';             //全局引入element
import 'element-ui/lib/theme-chalk/index.css';    //全局引入element的样式
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos';
import './style/aos.css';
import './js/aos.js';
import $ from 'jquery';
import api from './utils/axios'
import './utils/rem'

Vue.prototype.$ = $
Vue.config.productionTip = false

Vue.use(ElementUI,AOS);     //全局注入element
Vue.prototype.$api = api;

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import LeftNav from '@/components/leftNav.vue';// 引入
Vue.component("LeftNav",LeftNav);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
