<template>
  <div id="app" style="width: 100%">
    <topNav data-aos="fade-down"
          data-aos-duration="600"
          data-aos-delay="0"/>
    <router-view/>    
    <bottom/>
  </div>
</template>

<script>
import bottom from './components/bottom.vue'
import topNav from './components/topNav.vue'
export default {
  name: 'App',
  components: {
    bottom, 
    topNav,
  },
  mounted(){
    AOS.init();
  }
}
</script>

<style>
@import "@/style/eui.css";
@import "@/style/swiper.min.css";
@import "@/style/aos.css";
@import "@/style/font-awesome.min.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}
@media screen and (max-width: 750px){
  body {min-width: 100%;width: 100%}
  .eui-in-main {margin: 0;}
  .pub-con {width: 100%}
}
</style>
